import React from "react";
import {useLocation, useSearchParams} from "react-router-dom";
import {TranslationObject} from "../../models/translationModal";
import {ProfilePayload} from "../../models/userModels";

interface ServiceNotificationProps {
    entities?: TranslationObject;
    profile?: ProfilePayload;
    currentLanguage: string
}

export const ServiceNotification: React.FC<ServiceNotificationProps> = ({
        entities,
        profile,
        currentLanguage
    }) => {
    const container = document.getElementById('root') || document.body;
    const {pathname} = useLocation();
    const [searchParams] = useSearchParams();

    const filteredServices = profile?.services?.filter(service =>
        service.is_remote === false &&
        !service.specialist_address &&
        !service.customer_address
    );

    const invalidServices = filteredServices?.map(service => (
        `<strong><a href="/${currentLanguage}/specialist/lk/profile?type=service&step=description&id=${service.id}">${service.service_type.name}</a></strong>`
    )).join(', ') ?? '';

    const servicesCount = filteredServices?.length || 0; // Подсчет количества услуг

    if (servicesCount === 0) {
        return null;
    }

    const serviceText = servicesCount === 1
        ? entities?.common_place_one_service_added?.value.replace(':services', invalidServices)
        : entities?.common_place_several_services_added?.value.replace(':services', invalidServices);

    const jsxCookies = () => (
        <div className="cookies services-notification">
            <div className="services__container">
                <p
                    className="services__text"
                    dangerouslySetInnerHTML={{
                        __html: serviceText || "",
                    }}
                />
            </div>
        </div>
    );
    return jsxCookies();
};
