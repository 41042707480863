import React, { useEffect, useState } from "react";
import SubMenu from "../../components/SubMenu";
import SelectServiceCategories from "./SelectServiceCategories";
import { ComponentProps } from "../../models/models";
import SelectServiceType from "./SelectServiceType";
import SelectServiceSubTypes from "./SelectServiceSubTypes";
import Filters from "./Filters";
import { getTkn, getUrlAfterAuthorization } from "../../store/user/selectors";
import {
    useCreateOrderMutation,
} from "../../store/order/order.api";

import SelectWhereToMeet from "./SelectWhereToMeet";
import SelectAddress from "./SelectAddress";
import { endpoints } from "../../store/directories/directories.api";
import { Route, Routes, useLocation, useNavigate, useParams } from "react-router-dom";
import StartAt from "./StartAt";
import RepeatOrder from "./RepeatOrder";
import Wishes from "./Wishes";
import Finishing from "./Finishing";
import { useActions } from "../../hooks/actions";
import Specialists from "./Specialists";
import {
    clearOrderDataInLocalStorage,
    getItemCreateOrder,
    getOrderBody,
    updateItemCreateOrder,
} from "../../store/order/selectors";
import ProgressBar from "./ProgressBar";
import CurrentSpecialists from "./CurrentSpecialists";
import Specialist from "./Specialist";
import {
    ID,
    IS_INDIVIDUAL,
    IS_MY_ADDRESS,
    IS_PRIVATE,
    IS_SPECIALIST_ADDRESS,
    SERVICE_CATEGORY_ID,
    SERVICE_TYPE_ID,
    SPECIALIST_IDS,
    SPECIALIST_NAME,
} from "../../store/order/constant";
import { ProfilePayload } from "../../models/userModels";
import { useUpdateOrderMutation } from "../../store/orders/orders.api";
import { createOrderState } from "../../store/order/createOrder.slice";
import { getItemStore, scrollTop } from "../../untils";
import Loader from "../../components/Loader";

export interface OrderStepsProps extends ComponentProps {
    cancelOrder?: () => void;
    back?: (value: string) => void;
    error?: any;
    onSubmit: (
        e?: React.FormEvent<HTMLInputElement>,
        nextStep?: string
    ) => Promise<{ data: any } | { error: any } | undefined>;
    onSuccess: (value: string) => void;
    isSuccess?: boolean;
    isPrivate?: boolean;
    isIndividualOrder?: boolean;
    profile?: ProfilePayload;
    currentLanguage?: string;
}

interface Props extends ComponentProps {
    profile?: ProfilePayload;
}

const CreateOrder: React.FC<Props> = ({
    currentLanguage,
    entities,
    profile,
}) => {
    const navigate = useNavigate();
    const location = useLocation();
    const urlAfterAuthorization = getUrlAfterAuthorization();
    const { updateUserField, updateCreateOrderField, clearCreateOrderFields } =
        useActions();
    const categoryId = getItemCreateOrder(SERVICE_CATEGORY_ID);
    const specialistIds = getItemCreateOrder(SPECIALIST_IDS);
    const activeType = getItemCreateOrder(SERVICE_TYPE_ID);
    const isIndividualOrder = getItemCreateOrder(IS_INDIVIDUAL) || false;
    const [getFilters, { data: filters }] = endpoints.getFilters.useLazyQuery();
    const activeStep = getItemCreateOrder("activeStep");
    const token = getTkn();
    const isPrivate = getItemCreateOrder(IS_PRIVATE) || false;
    const id = getItemCreateOrder(ID);
    const isEdit = !!getItemCreateOrder(ID);
    const hasMyAddress = getItemCreateOrder(IS_MY_ADDRESS);
    const hasSpecialistAddress = getItemCreateOrder(IS_SPECIALIST_ADDRESS);
    const specialistName = getItemCreateOrder(SPECIALIST_NAME);
    const userId = getItemCreateOrder("userId");
    const isAllowSelectSpec = getItemCreateOrder("isAllowSelectSpecialist");
    const [hasFilters, setHasFilters] = useState(false);
    const [activeFilter, setActiveFilter] = useState(0);
    const [isNewData, setIsNewData] = useState(false);
    const isAdvertising = getItemCreateOrder('isAdvertising');
    const [isLoadingData, setIsLoadingData] = useState(false);
    const [getServiceCategories, { data: serviceCategories, isUninitialized: isUninitializedCategories }] = endpoints.getServiceCategories.useLazyQuery();
    const [getServiceTypes, { data: serviceTypes, isUninitialized: isUninitializedTypes }] = endpoints.getServiceTypes.useLazyQuery();
    const [getServiceSubTypes, { data: serviceSubTypes, isUninitialized: isUninitializedSubtypes }] = endpoints.getServiceSubTypes.useLazyQuery();

    const [updateOrder, { error: errorUpdate, isSuccess: isSuccessUpdate }] =
        useUpdateOrderMutation();
    const [createOrder, { data, error: errorCreate, isSuccess, isLoading }] =
        useCreateOrderMutation();

    const initialSteps = [
        "category",
        "create-order",
        "filters",
        "meeting-point",
        "select-my-address",
        "select-specialist-address",
        "when-to-start",
        "repeat",
        "wishes",
        "finishing",
        "list-of-specialists",
        "selected_specialists",
    ];

    useEffect(() => {
        if (filters?.length) {
            if (filters?.length > 0) {
                setHasFilters(true);
            } else {
                setHasFilters(false);
            }
        }
    }, [filters]);

    useEffect(() => {
        if (typeof activeType === "number") {
            getFilters({
                id: activeType,
            });
        } else {
            setHasFilters(false);
        }
    }, [activeType]);

    const error = isEdit ? errorUpdate : errorCreate;

    const clearSpecialistIds = () => {
        updateField({
            name: SPECIALIST_IDS,
            value: [],
        });
    };

    const updateField = <K extends keyof createOrderState>({
        name,
        value,
    }: {
        name: K;
        value: createOrderState[K];
    }) => {
        updateItemCreateOrder(
            {
                name,
                value,
            },
            updateCreateOrderField
        );
    };

    const changeSpecialistIds = (id: number, name?: string) => {
        if (specialistIds && id) {
            const findSpec = specialistIds.indexOf(id) !== -1;
            const specialistIdsNew = findSpec
                ? specialistIds.filter(
                    (specialistId: number) => specialistId !== id
                )
                : specialistIds.concat(id)

            updateField({
                name: SPECIALIST_IDS,
                value: specialistIdsNew
            });

            !specialistName && updateField({
                name: SPECIALIST_NAME,
                value: name
            });
        }
    };

    const changeIsAllowSelectSpec = (allow: boolean) => {
        updateField({
            name: 'isAllowSelectSpecialist',
            value: allow
        });
    }

    useEffect(() => {
        load();
        return () => {
            if (isIndividualOrder) {
                const hasErrorInAddress = getItemStore("hasErrorInAddress");
                const exceptions: (keyof createOrderState)[] = hasErrorInAddress
                    ? [IS_INDIVIDUAL]
                    : [];
                clearOrderDataInLocalStorage(false, exceptions);
                clearCreateOrderFields(exceptions);
            }
        };
    }, []);

    const load = () => {
        console.log('activeStep', activeStep)
        if (profile?.id && userId) {
            if (Number(userId) !== profile?.id) {
                clearOrderDataInLocalStorage(false, []);
                clearCreateOrderFields();
                navigate(`/${currentLanguage}/customer/create-order`);
                return;
            }
        }

        if (
            /category-(.*)?|category-(.*)?\/type|category-(.*)?\/type-(.*)?|category-(.*)?\/type-(.*)?\/subtype|category-(.*)?\/type-(.*)?\/subtype-(.*)?/gi.test(
                location.pathname
            )
        ) {
            getServiceCategories();
            setIsLoadingData(true);
        } else {
            const paths = location.pathname.split('/');
            const lastStep = paths[paths.length - 1];

            if (lastStep && !initialSteps.includes(lastStep)) {
                goToNotFound();
                return;
            }
        }

        if (!categoryId) {
            updateField({
                name: IS_PRIVATE,
                value: isPrivate || false,
            });
        }

        const url =
            activeStep &&
            `/${currentLanguage}/customer/create-order/${activeStep}`;
        if (url) {
            navigate(url);
        }

        if (urlAfterAuthorization) {
            updateUserField({
                name: urlAfterAuthorization,
                value: null,
            });
        }
    };

    useEffect(() => {
        if (isUninitializedCategories) return;

        if (!serviceCategories?.length || categoryId) {
            setIsLoadingData(false);
            return;
        }

        const categoryStep = location.pathname
            .split('/')
            .find(item => /category-/gi.test(item));

        if (!categoryStep) {
            goToNotFound();
            return;
        }

        const categorySlug = categoryStep?.replace(/^category-/gi, '') || '';
        const selectedCat = serviceCategories?.find(item => item.slug === categorySlug);

        if (!selectedCat) {
            goToNotFound();
            return;
        } else {
            if (activeType) {
                setIsLoadingData(false);
                return;
            }

            if (/type-(.*)?|type-(.*)?\/subtype|type-(.*)?\/subtype-(.*)?/gi.test(location.pathname)) {
                getServiceTypes({ id: selectedCat?.id });
            } else {
                if (/type\/|type$/gi.test(location.pathname)) {
                    setIsLoadingData(false);
                    return
                }

                goToNotFound();
            }
        }
    }, [serviceCategories]);

    useEffect(() => {
        if (isUninitializedTypes) return;

        if (!serviceTypes?.length || activeType) {
            setIsLoadingData(false);
            return;
        }

        const typeStep = location.pathname
            .split('/')
            .find(item => /type-/gi.test(item));
        if (!typeStep) {
            goToNotFound();
            return;
        }

        const typeSlug = typeStep?.replace(/^type-/gi, '') || '';
        const selectedType = serviceTypes?.find(item => item.slug === typeSlug);

        if (!selectedType) {
            goToNotFound();
            return;
        } else {
            if (/subtype$/gi.test(location.pathname)) {
                setIsLoadingData(false);
                return;
            }
            if (/subtype-(.*)?/gi.test(location.pathname)) {
                getServiceSubTypes({ id: selectedType?.id });
            } else {
                goToNotFound();
            }
        }
    }, [serviceTypes]);

    useEffect(() => {
        if (isUninitializedSubtypes) return;

        if (!serviceSubTypes?.length) {
            setIsLoadingData(false);
            return;
        }

        const subtypeStep = location.pathname
            .split('/')
            .find(item => /subtype-/gi.test(item));

        if (!subtypeStep) {
            goToNotFound()
            return;
        }

        const subtypeSlug = subtypeStep?.replace(/^subtype-/gi, '') || '';
        const selectedSubtype = serviceSubTypes?.find(item => item.slug === subtypeSlug);

        if (!selectedSubtype) {
            goToNotFound();
            return;
        }

        setIsLoadingData(false);
    }, [serviceSubTypes]);

    useEffect(() => {
        if (profile?.id) {
            updateField({
                name: "userId",
                value: profile.id,
            });
        }
        if (profile?.user_role === "specialist" && token) {
            navigate(`/${currentLanguage}/specialist/notfound`);
        }
    }, [profile]);

    useEffect(() => {
        load()
    }, [currentLanguage])

    const goToNotFound = () => {
        setIsLoadingData(false);
        navigate(profile?.user_role ? `/${currentLanguage}/${profile?.user_role}/not-found` :  `/${currentLanguage}/not-found`);
    }

    const onSuccess = (step?: string) => {
        setIsNewData(false);
        if (!isEdit && isSuccess) {
            updateField({
                name: ID,
                value: data?.id,
            });
        }
        if (step) {
            handleChangeStep(step);
            navigate(`/${currentLanguage}/customer/create-order/${step}`);
        }
    };

    const onSubmit = async (
        e?: React.FormEvent<HTMLInputElement>,
        nextStep?: string
    ) => {
        setIsNewData(true);
        if (e) {
            e.preventDefault();
        }
        const body: createOrderState = getOrderBody();

        if (profile?.id && token) {
            updateField({
                name: "userId",
                value: profile?.id,
            });
            if (!isEdit && activeStep === "finishing") {
                return createOrder({ body });
            } else {
                if (body?.id && activeStep === "finishing") {
                    return updateOrder({
                        id: body?.id,
                        body,
                    });
                }
            }
        }

        if (nextStep) {
            handleChangeStep(nextStep);
            navigate(
                `/${currentLanguage}/customer/create-order/${nextStep}`
            );
            scrollTop();
        }
    };

    const handleChangeStep = (step: string) => {
        updateField({
            name: "activeStep",
            value: step,
        });
    };

    const back = (step: string) => {
        handleChangeStep(step);
        scrollTop();
    };

    const props = {
        currentLanguage: currentLanguage,
        entities: entities,
        error: error,
        onSubmit: onSubmit,
        onSuccess: onSuccess,
        isSuccess: !isEdit
            ? isSuccess && isNewData
            : isSuccessUpdate && isNewData,
        isIndividualOrder: isIndividualOrder,
        profile: profile,
    };

    if (isLoadingData) {
        return <Loader />;
    }

    return (
        <main>
            <SubMenu entities={entities} profile={profile} />
            <div className="page">
                <section className="new-order">
                    <ProgressBar
                        isPrivate={isPrivate}
                        activeFilter={activeFilter}
                        filters={filters}
                        hasFilters={hasFilters}
                        hasMyAddress={hasMyAddress}
                        hasSpecialistAddress={hasSpecialistAddress}
                        isIndividualOrder={isIndividualOrder}
                    />
                    <Routes>
                        <Route
                            path="/"
                            element={<SelectServiceCategories {...props}
                                currentLanguage={currentLanguage}
                            />}
                        />
                        <Route
                            path="/category"
                            element={<SelectServiceCategories {...props}
                                currentLanguage={currentLanguage}
                            />}
                        />
                        <Route
                            path="/:category"
                            element={<SelectServiceCategories {...props}
                            currentLanguage={currentLanguage}
                        />}
                        />
                        <Route
                            path="/:category/type"
                            element={<SelectServiceType
                                {...props}
                                setHasFilters={setHasFilters}
                                isIndividualOrder={isIndividualOrder}
                            />}
                        />
                        <Route
                            path="/:category/:type"
                            element={
                                <SelectServiceType
                                    {...props}
                                    setHasFilters={setHasFilters}
                                    isIndividualOrder={isIndividualOrder}
                                />
                            }
                        />
                        <Route
                            path="/:category/:type/subtype"
                            element={
                                <SelectServiceSubTypes
                                    {...props}
                                    hasFilters={hasFilters}
                                    isPrivate={isPrivate}
                                    isIndividualOrder={isIndividualOrder}
                                />
                            }
                        />
                        <Route
                            path="/:category/:type/:subtype"
                            element={
                                <SelectServiceSubTypes
                                    {...props}
                                    hasFilters={hasFilters}
                                    isPrivate={isPrivate}
                                    isIndividualOrder={isIndividualOrder}
                                />
                            }
                        />
                        <Route
                            path="filters"
                            element={
                                <Filters
                                    filters={filters || []}
                                    back={() => handleChangeStep("subtype")}
                                    setActiveFilter={setActiveFilter}
                                    activeFilter={activeFilter}
                                    {...props}
                                />
                            }
                        />
                        <Route
                            path="meeting-point"
                            element={
                                <SelectWhereToMeet
                                    {...props}
                                    hasFilters={hasFilters}
                                />
                            }
                        />
                        <Route
                            path="select-my-address"
                            element={
                                <SelectAddress
                                    {...props}
                                    isIndividualOrder={isIndividualOrder}
                                    isMyAddress={true}
                                    profile={profile}
                                />
                            }
                        />
                        <Route
                            path="select-specialist-address"
                            element={
                                <SelectAddress
                                    {...props}
                                    isMyAddress={false}
                                    isAdvertising={isAdvertising}
                                    back={back}
                                />
                            }
                        />
                        <Route
                            path="when-to-start"
                            element={
                                <StartAt
                                    {...props}
                                    back={back}
                                    isPrivate={isPrivate}
                                />
                            }
                        />
                        <Route
                            path="repeat"
                            element={<RepeatOrder {...props} back={back} />}
                        />
                        <Route path="wishes" element={<Wishes {...props} />} />
                        <Route
                            path="finishing"
                            element={
                                <Finishing
                                    {...props}
                                    profile={profile}
                                    back={back}
                                    isLoading={isLoading}
                                />
                            }
                        />
                        <Route
                            path="/:category/:type/:subtype/list-of-specialists"
                            element={
                                <Specialists
                                    isPrivate={isPrivate}
                                    clearSpecialistIds={clearSpecialistIds}
                                    specialistIds={specialistIds || []}
                                    changeSpecialistIds={changeSpecialistIds}
                                    changeIsAllowSelectSpec={changeIsAllowSelectSpec}
                                    {...props}
                                />
                            }
                        />
                        <Route
                            path="/select-specialists"
                            element={
                                <Specialists
                                    isPrivate={isPrivate}
                                    clearSpecialistIds={clearSpecialistIds}
                                    specialistIds={specialistIds || []}
                                    changeSpecialistIds={changeSpecialistIds}
                                    changeIsAllowSelectSpec={changeIsAllowSelectSpec}
                                    {...props}
                                />
                            }
                        />
                        <Route
                            path="selected_specialists"
                            element={
                                <CurrentSpecialists
                                    specialistIds={specialistIds || []}
                                    changeSpecialistIds={changeSpecialistIds}
                                    entities={entities}
                                    isPrivate={isPrivate}
                                    isEdit={false}
                                />
                            }
                        />
                        <Route
                            path="specialist/:id"
                            element={
                                <Specialist
                                    isEdit={false}
                                    isPrivate={isPrivate}
                                    clearSpecialistIds={clearSpecialistIds}
                                    specialistIds={specialistIds || []}
                                    changeSpecialistIds={changeSpecialistIds}
                                    {...props}
                                />
                            }
                        />
                    </Routes>
                </section>
            </div>
        </main>
    );
};
export default CreateOrder;
