import React, { useEffect, useState } from "react";
import Icon from "../../../../components/Icon";
import Modal from "../../../../components/Modal";
import Form from "../../../../components/Form/Form";
import Input from "../../../../components/Form/Input";

import _ from "lodash";
import {
	IAchievement,
	IAchievementDocument,
	ProfilePayload,
} from "../../../../models/userModels";
import { TranslationObject } from "../../../../models/translationModal";
import {
	useCreateUserAchievementMutation,
	useDeleteUserAchievementDocumentMutation,
	useDeleteUserAchievementMutation,
	useUpdateUserAchievementMutation,
} from "../../../../store/user/user.api";
import { getTkn } from "../../../../store/user/selectors";
import { getCurrentLanguage } from "../../../../store/directories/selectors";
import cn from "classnames";
import { useLoading } from "../../../../hooks/useLoading/useLoading";
import ConfirmDeleteModal from "../../../../components/Modals/ConfirmDeleteModal/ConfirmDeleteModal";
interface ProfessionalAchievementsProps {
	entities?: TranslationObject;
	profile?: ProfilePayload;
	userVerification?: "verified" | "unverified";
	userPublished?: boolean;
	userSuspended?: boolean;
}

export const ProfessionalAchievements: React.FC<
	ProfessionalAchievementsProps
> = ({ entities, profile, userVerification, userPublished, userSuspended }) => {
	const [
		createUserAchievement,
		{ isSuccess: isSuccessCreate, isLoading: isLoadingCreate },
	] = useCreateUserAchievementMutation();

	const [deleteUserAchievement] = useDeleteUserAchievementMutation();

	const [
		updateUserAchievement,
		{ isSuccess: isSuccessUpdate, isLoading: isLoadingUpdate },
	] = useUpdateUserAchievementMutation();

	const [deleteUserAchievementDocument] =
		useDeleteUserAchievementDocumentMutation();

	const token = getTkn();

	const currentLanguage = getCurrentLanguage();

	const { achievements: userAchievements } = profile || {};

	const [openAchievementId, setOpenAchievementId] = useState<number | null>(
		null
	);

	const [modalInputTitleValue, setModalInputTitleValue] =
		useState<string>("");

	const [modalInputYearValue, setModalInputYearValue] = useState<string>("");

	const [visibleModal, setVisibleModal] = useState<boolean>(false);

	const [newFiles, setNewFiles] = useState<
		Array<{
			id: number;
			name: string;
			file: Blob;
			link: string;
			extension: string;
		}>
	>([]);

	const [oldFiles, setOldFiles] = useState<IAchievementDocument[]>([]);

	const [validationError, setValidationError] = useState<unknown>(null);

	useEffect(() => {
		if (visibleModal && openAchievementId && userAchievements?.length) {
			const oldFilesAchievement = userAchievements?.find(
				(achievement) => {
					return achievement?.id == openAchievementId;
				}
			);
			if (oldFilesAchievement?.documents) {
				const oldFiles = oldFilesAchievement?.documents.map((file) => ({
					...file,
					extension: file.file_name.split(".").pop(),
				}));
				setOldFiles(oldFiles);
			}
		}
	}, [userAchievements]);

	const addFile = (e: React.ChangeEvent<HTMLInputElement>): void => {
		const elems: FileList | null = e.target.files;
		if (elems) {
			const keys = Object.keys(elems);
			const items: any[] = [];

			keys.forEach((elem: any) => {
				const file = elems[elem];
				if (file) {
					items.push({
						id: +_.uniqueId(),
						file: file,
						name: file.name,
						link: URL.createObjectURL(file),
						extension: file.type.split("/").pop(),
					});
				}
			});
			setNewFiles((oldFiles) => [...oldFiles, ...items]);
		}
	};

	const onSubmit = (e: any, achievementId: number | null): void => {
		e.preventDefault();
		setValidationError(null);

		const body = new FormData(e.target);

		if (body.get("year") === "") {
			body.delete("year");
		}

		body.delete("file");

		newFiles.forEach(({ file }) => {
			body.append(`documents[${_.uniqueId()}]`, file);
		});

		if (achievementId) {
			updateUserAchievement({
				achievementId,
				body,
				currentLanguage,
				token,
			})
				.unwrap()
				.catch((e) =>
					setValidationError({
						status: e.status,
						data: {
							description: e.data?.description,
							payload: {
								errors: { title: { Required: [] } },
								messages: {
									title: [
										e?.data?.description ??
											"field is required",
									],
								},
							},
						},
					})
				);
		} else {
			createUserAchievement({ body, currentLanguage, token })
				.unwrap()
				.catch((e) =>
					setValidationError({
						status: e.status,
						data: {
							description: e.data?.description,
							payload: {
								errors: { title: { Required: [] } },
								messages: {
									title: [
										e?.data?.description ??
											"field is required",
									],
								},
							},
						},
					})
				);
		}
	};

	const handleChangeModalInputTitle = (inputValue: string) => {
		setModalInputTitleValue(inputValue);
	};

	const handleChangeModalInputYear = (inputValue: string) => {
		setModalInputYearValue(inputValue);
	};

	const { loading, setLoading } = useLoading();

	const handleClickOpenOldAchievement = (achievement: IAchievement) => () => {
		if (
			userSuspended ||
			!userPublished ||
			['verified', 'unverified'].includes(userVerification)
		) {
			const { id, title, year, documents } = achievement || {};

			const oldFiles = documents.map((file) => ({
				...file,
				extension: file.file_name.split(".").pop(),
			}));

			if (id) {
				setOpenAchievementId(id);
			} else {
				return;
			}

			if (title) {
				setModalInputTitleValue(title);
			}

			if (year) {
				setModalInputYearValue(String(year));
			}

			if (oldFiles?.length) {
				setOldFiles(oldFiles);
			}

			setVisibleModal(true);
		}
	};

	const openAchievementModal = () => {
		setVisibleModal(true);
	};

	const closeAchievementModal = () => {
		setVisibleModal(false);
		setOpenAchievementId(null);
		setModalInputTitleValue("");
		setModalInputYearValue("");
		setNewFiles([]);
		setOldFiles([]);
		setValidationError(null);
	};

	const deleteNewFile = (fileId: number) => {
		setNewFiles((files) => {
			return files.filter((file) => file?.id !== fileId);
		});
	};

	const deleteOldFile = (
		achievementId: number | null,
		documentId: number | null
	) => {
		if (!achievementId || !documentId) return;

		deleteUserAchievementDocument({
			achievementId,
			documentId,
			currentLanguage,
			token,
		});
	};

	const handleCLickDeleteUserAchievement =
		(achievementId: number): (() => void) =>
		() => {
			if (!achievementId) return;

			deleteUserAchievement({
				achievementId,
				currentLanguage,
				token,
			});
		};

	useEffect(() => {
		if ((isLoadingCreate || isLoadingUpdate) && !loading) {
			setLoading(true);
		} else if (loading) {
			setLoading(false);
		}
	}, [isLoadingCreate, isLoadingUpdate]);

	useEffect(() => {
		if (isSuccessUpdate && !loading) {
			closeAchievementModal();
		}
	}, [isSuccessUpdate, loading]);

	useEffect(() => {
		if (isSuccessCreate && !loading) {
			closeAchievementModal();
		}
	}, [isSuccessCreate, loading]);

	return (
		<>
			<div
				className={cn("specialist-profile__block", {
					["specialist-profile__block--flex"]:
						!userAchievements?.length,
				})}
			>
				<div className="specialist-profile__col">
					<h3 className="specialist-profile__subtitle title title--h3">
						{
							entities
								?.specialist_details_professional_achievements
								?.value
						}
					</h3>
					<p className="text color-grey">
						{entities?.profile_specialist_education_about?.value}
					</p>
				</div>
				{(userAchievements?.length && (
					<div className="profile-block grey-card profile-block--prof">
						{userAchievements?.map((achievement: IAchievement) => (
							<div
								className="profile-block__row"
								key={achievement?.id}
							>
								<div className="profile-block__col">
									<p className="text profile-block__text profile-block__list-item">
										{achievement?.title}
										<span className="profile-block__clip">{(!!achievement?.documents?.length) && <Icon type="clip" />}</span>
										<span className="profile-block__year">{achievement?.year}</span>
									</p>
								</div>
								<div className="profile-block__controls">
									<button
										onClick={handleClickOpenOldAchievement(
											achievement
										)}
										className="profile-block__btn btn btn--no-bg"
										type="button"
										aria-label={
											entities?.order_edit?.value ??
											"edit"
										}
									>
										<Icon
											type="edit-current-color"
											width={20}
											height={20}
											colorVariant={"jaguar"}
										/>
									</button>
									<ConfirmDeleteModal
										entities={entities}
										classes={["profile-block__btn", "btn", "btn--no-bg"]}
										handleClick={handleCLickDeleteUserAchievement(
											achievement?.id
										)}
										ariaLabel={entities?.profile_destroy_button_ok?.value}
									>
										<Icon
											type="close-input-current-color"
											width={20}
											height={20}
											colorVariant={"grayLight"}
										/>
									</ConfirmDeleteModal>
								</div>
							</div>
						))}
					</div>
				)) ||
					null}
				<button
					onClick={openAchievementModal}
					className={cn("btn btn--green", {
						["specialist-profile__btn"]: !userAchievements?.length,
						["specialist-profile__block-btn"]:
							userAchievements?.length,
					})}
					type="button"
				>
					{entities?.specialist_details_add?.value}
				</button>
			</div>
			<Modal
				visible={visibleModal}
				onClose={closeAchievementModal}
				modificators={["modal-specialist"]}
				title={
					entities?.specialist_details_professional_achievements
						?.value
				}
				asterisk={false}
			>
				<div className="form">
					<Form
						onSubmit={(event: React.FormEvent<HTMLFormElement>) =>
							onSubmit(event, openAchievementId)
						}
						error={validationError}
					>
						{(invalidFields, error) => (
							<>
								<div className="form__row form__row--medium-margin">
									<Input
										placeholder={
											entities
												?.specialist_details_document_name
												?.value || ""
										}
										classes={["form__field"]}
										type={"text"}
										id={"title"}
										name={"title"}
										value={modalInputTitleValue}
										onChange={handleChangeModalInputTitle}
										invalidFields={invalidFields}
										error={error}
									/>
								</div>
								<div className="form__row form__row--medium-margin">
									<Input
										classes={[
											"form__field",
											"form__field--w30",
										]}
										id={"year"}
										name={"year"}
										type={"string"}
										placeholder={
											entities
												?.specialist_details_document_date
												?.value || ""
										}
										value={modalInputYearValue}
										onChange={handleChangeModalInputYear}
										invalidFields={invalidFields}
										error={error}
									/>
								</div>
								<h2 className="modal__content-title title title--f24">
									{entities?.specialist_details_add_document
										?.value || ""}
								</h2>
								<div className="form__grid form__grid--4x">
									{oldFiles?.map((file) => (
										<a
											href={file?.original_url}
											key={file?.id}
											target={"_blank"}
										>
											<div
												className={cn(
													"upload not-empty",
													{
														img:
															file?.extension !==
															"pdf",
													}
												)}
											>
												<button
													onClick={(event) => {
														event?.preventDefault();
														deleteOldFile(
															openAchievementId,
															file?.id
														);
													}}
													className="upload__delete-btn btn btn--no-bg"
													type="button"
													aria-label={
														entities
															?.profile_destroy_button_ok
															?.value
													}
												>
													<Icon
														type={
															"delete-current-color"
														}
														colorVariant={
															"grayLight"
														}
														height={16}
														width={16}
													/>
												</button>
												{(file?.extension === "pdf" && (
													<span className="upload__file-info">
														{file?.file_name}
													</span>
												)) || (
													<img
														src={file.original_url}
														alt="document"
													/>
												)}
											</div>
										</a>
									))}
									{newFiles?.map(
										({ id, name, link, extension }) => (
											<a
												href={link}
												target={"_blank"}
												key={id}
											>
												<div
													className={cn(
														"upload not-empty",
														{
															img:
																extension !==
																"pdf",
														}
													)}
												>
													<button
														onClick={(event) => {
															event.preventDefault();
															deleteNewFile(id);
														}}
														className="upload__delete-btn btn btn--no-bg"
														type="button"
														aria-label={
															entities
																?.profile_destroy_button_ok
																?.value
														}
													>
														<Icon
															type={
																"delete-current-color"
															}
															colorVariant={
																"grayLight"
															}
															height={16}
															width={16}
														/>
													</button>
													{(extension === "pdf" && (
														<span className="upload__file-info">
															{name}
														</span>
													)) || (
														<img
															src={link}
															alt="document"
														/>
													)}
												</div>
											</a>
										)
									)}
									<div className="upload form__field">
										<label className="upload__field">
											<Icon
												type={"upload"}
												width={40}
												height={40}
											/>
											<input
												type="file"
												name="file"
												id="file"
												onChange={addFile}
												accept="image/png, image/gif, image/jpeg, image/bmp, image/webp,  application/pdf"
											/>
										</label>
									</div>
								</div>
								<div className="form__row form__controls form__controls--right">
									<button
										onClick={closeAchievementModal}
										className="btn form__back-link btn--transparent"
										type="button"
									>
										{entities?.common_cancel_button?.value}
									</button>
									<button
										className="btn btn--transparent btn--bg-green"
										type="submit"
									>
										{entities?.common_save_button?.value}
									</button>
								</div>
							</>
						)}
					</Form>
				</div>
			</Modal>
		</>
	);
};
