import React, { useEffect, useRef, useState } from "react";
import ReactDOM from 'react-dom';
import cn from "classnames";
import { formatQueryString, convertDateToDDMMYYYY} from "../../untils";
import Icon from "../Icon";
import { endpoints } from "../../store/orders/orders.api";
import { OrderItem } from "../../models/ordersModel";
import { useUserRole } from "../../hooks/useUserRole";
import { getCurrentLanguage } from "../../store/directories/selectors";
import SimpleBar from "simplebar-react";
import { TranslationObject } from "../../models/translationModal";
import { useSelector } from "react-redux";
import { useActions } from "../../hooks/actions";
import { isUpdateChatSelector, isCreateNewChatSelector } from "../../store/chat/chat.selectors";
import pictureSupportPng from "../../img/content/support.png";

interface Props {
  entities?: TranslationObject;
  isSpecialist?: boolean;
  isAuth?: boolean;
}

const Chat: React.FC<Props> = ({ entities, isSpecialist = false, isAuth }) => {
  const isFirefox = window.navigator.userAgent.indexOf('Firefox') !== -1;
  const container = document.getElementById('root') || document.body;
  const { role } = useUserRole(isSpecialist ? "specialist" : "customer");
  const currentLanguage = getCurrentLanguage();
  const linkToOrder = `/${currentLanguage}/${role}/lk/order`;

  const scrollableNodeRef = useRef<HTMLDivElement | null>(null);

  const [getOrders, { data: orders, isLoading, error }] = endpoints.getOrders.useLazyQuery();
  const [pages, setPages] = useState<number>(1);
  const [showChats, setShowChats] = useState(false);
  const [allOrders, setAllOrders] = useState<OrderItem[]>([]);
  const { setIsUpdateChat, setIsCreateNewChat } = useActions();
  const isUpdateChat = useSelector(isUpdateChatSelector);
  const isCreateNewChat = useSelector(isCreateNewChatSelector);

  // Метод запроса заказов по чатам
  const sendGetOrders = (pages) => {
    getOrders({
      params: formatQueryString({
        page: pages,
        per_page: 10,
        status: "all",
        is_chats: 1,
        is_web: 1
      }),
    });
  };

  const updateOrderList = (items: OrderItem[], isState: boolean, setCalled) => {
    const startPage = 1

    if (isState) {
      setAllOrders(items);
      if (pages === startPage) {
        sendGetOrders(startPage);
      }
      setPages(startPage);
      setCalled(false);
    }
  };

  useEffect(() => {
    sendGetOrders(pages);
  }, [pages]);

  // Обновление списка чатов, за счет новых сообщений
  useEffect(() => {
    updateOrderList([], isUpdateChat, setIsUpdateChat);
  }, [isUpdateChat]);

  // Обновление списа чатов, за счет созданного нового Заказа
  useEffect(() => {
    updateOrderList(orders?.items || [], isCreateNewChat, setIsCreateNewChat);
  }, [isCreateNewChat]);

  useEffect(() => {
    setAllOrders(prevOrders => {
      return [...prevOrders, ...(orders?.items || [])];
    });
  }, [orders]);

  useEffect(() => {
    if (!isAuth) {
      setAllOrders([]);
    } else {
      sendGetOrders(1)
      setTimeout(() => setAllOrders(orders?.items || []), 1)
    }
  }, [isAuth]);

  const openChatsList = () => {
    setShowChats(true);
  };

  const closeChatsList = () => {
    setShowChats(false);
  };

  const getLinkUrl = (order: OrderItem) =>
    !!order?.chats_user?.length && !(role === 'customer' && (order?.responses_count && order.responses_count > 1))
      ? `${linkToOrder}/${order?.id}/chat/${order?.chats_user[0]?.id}`
      : `${linkToOrder}/${order?.id}`;

  const scrollChatList = () => {
    const height = scrollableNodeRef?.current?.offsetHeight || 0;
    const heightScroll = (scrollableNodeRef?.current?.children[0].scrollHeight || 0) - 2;
    const scrollTop = scrollableNodeRef?.current?.scrollTop || 0;

    const totalPages = Math.ceil((orders?.total || 10) / 10);

    if (Math.ceil(scrollTop) >= (heightScroll - height) && totalPages !== pages) {
      setPages(pages + 1);
    }
  }

  if (isLoading) {
    return null;
  }

  const jsxChat = () => (
    <div className="chat-lk__container">
      <button className={cn("chat-lk__button btn", { "btn--green": isSpecialist })}
        onClick={openChatsList}
      >
        <Icon type="message" width={20} height={20} colorVariant="light" />
      </button>

      <div className={cn("chat-lk", {"active": showChats})}>
        <div className={cn("chat-lk__header", {
          "chat-lk__header--green": isSpecialist,
        })}>
          <h3>{entities?.chat_title?.value}</h3>
          <button className="chat-lk__button-icon" onClick={closeChatsList}>
            <Icon type="close-input" width={20} height={20} />
          </button>
        </div>
        <a href={`/${currentLanguage}/support`} className="chat-lk__card chat-lk__card--support">
          <img src={pictureSupportPng} alt="Support"/>
          <div className="chat-lk__card-title chat-lk__card-title--support">
            <span>{entities?.support_title?.value}</span>
          </div>
        </a>
        <div className={cn("chat-lk__content", {"chat-lk__content--empty": error || !allOrders.length})}>
          {(isAuth && error) && <div className="chat-lk__error-block">
            <p>{entities?.common_error?.value} {error?.data?.description}</p>
          </div>}
          {!allOrders.length && <div className="chat-lk__empty-block">
            <p>{entities?.common_list_is_empty?.value}</p>
          </div>}
          {!!allOrders.length
            && <SimpleBar
                className="chat-lk__content-container"
                autoHide={false}
                forceVisible={true}
                // style={{ maxHeight: 550 }}
                style={{ maxHeight: 494 }}
                scrollableNodeProps={{ ref: scrollableNodeRef, onScroll: scrollChatList }}
              >
                {allOrders.map((order: OrderItem) => (
                  <a href={getLinkUrl(order)} className="chat-lk__card">
                    <div className="chat-lk__card-date">{convertDateToDDMMYYYY(order?.created_at)}</div>
                    <div className="chat-lk__card-title">
                      <span>{order?.title}</span>
                      {!!order?.unviewed_chat_messages_count
                        && <div className={cn("chat-lk__card-unread", {
                              "chat-lk__card-unread--green": isSpecialist,
                            })}>
                            <span>{order?.unviewed_chat_messages_count}</span>
                          </div>
                      }
                    </div>
                  </a>
                ))}
            </SimpleBar>}
        </div>
      </div>
    </div>
  )

  return isFirefox ? ReactDOM.createPortal(jsxChat(), container) : jsxChat();
}

export default Chat;
