import React, { useEffect, useRef, useState } from "react";
import Icon from "../Icon";
import { Image } from "../../models/userModels";
import { TranslationObject } from "../../models/translationModal";

const PhotoSlider: React.FC<{
    photos: Image[];
    entities?: TranslationObject;
}> = ({ photos, entities }) => {
    const containerRef = useRef<HTMLDivElement>(null);
    const itemRef = useRef<HTMLDivElement>(null);
    const [translate, setTranslate] = useState(0);

    const scroll = (isRight: boolean) => {
        const itemWidth = itemRef.current?.offsetWidth || 0;
        setTranslate(prev => {
            const newTranslate = isRight ? prev + itemWidth : prev - itemWidth;
            return Math.max(newTranslate, 0); // Ограничиваем минимальное значение
        });
    };

    useEffect(() => {
        if (containerRef.current) {
            containerRef.current.scrollTo({
                left: translate,
                behavior: "smooth",
            });
        }
    }, [translate]);

    return (
        <div className="slider">
            <h3 className="slider__title title title--h4">
                {entities?.specialist_details_service_photo?.value}
            </h3>
            <div className="slider__wrapper no-scrollbar" ref={containerRef}>
                {photos?.map((elem, key) => (
                    <div className="slider__slide" key={key} ref={itemRef}>
                        <picture>
                            <img
                                src={elem?.original_url}
                                width="190"
                                height="190"
                                alt={`slide-${key + 1}`}
                            />
                        </picture>
                    </div>
                ))}
                {photos?.length > 4 && (
                    <button
                        className="slider__btn btn btn--nb go_right"
                        type="button"
                        onClick={() => scroll(true)}
                        aria-label="Next"
                    >
                        <Icon type="arrow-right" width={9} height={18} />
                    </button>
                )}
            </div>
        </div>
    );
};

export default PhotoSlider;
