import React, { useEffect, useState } from "react";
import { ProfileHeader } from "../../ProfileSpecialist/elements/ProfileHeader";
import { ProfessionalAchievements } from "../../ProfileSpecialist/elements/ProfileSpecialistContent/ProfessionalAchievements";
import { ComponentProps } from "../../../models/models";
import { TellUsAboutYourself } from "../../ProfileSpecialist/elements/ProfileSpecialistContent/TellUsAboutYourself";
import { ServicesYouProvide } from "../../ProfileSpecialist/elements/ProfileSpecialistContent/ServicesYouProvide";
import {
    NewServiceStepTypes,
    SpecialistNewService,
} from "../SpecialistNewService/SpecialistNewService";
import {
    endpoints,
    usePublishSpecialistProfileMutation,
} from "../../../store/user/user.api";
import { generatePath, useNavigate } from "react-router-dom";
import { useLoading } from "../../../hooks/useLoading/useLoading";
import { getSpecialistRole } from "../../ProfileSpecialist/utils";
import { AccountSettings } from "../../ProfileSpecialist/elements/ProfileSpecialistContent/AccountSettings";
import Profile from "../../Profile";
import { useNotification } from "../../../hooks/useNotification";
import DraftSpecialistModal from "../../../components/Modals/DraftSpecialistModal";

export const SpecialistQuestionnaire: React.FC<ComponentProps> = ({
    entities,
    currentLanguage,
}) => {
    const navigate = useNavigate();

    const { setLoading } = useLoading();

    const { setNotification } = useNotification();

    const [openDraftModal, setOpenDraftModal] = useState(false);

    const [
        publishSpecialistProfile,
        { isSuccess: publishIsSuccess, isLoading: publishIsLoading },
    ] = usePublishSpecialistProfileMutation();

    const [
        updateProfile,
        { currentData: profile, isLoading: updateProfileIsLoading },
    ] = endpoints.getProfile.useLazyQuery();

    const { name, family_name, avatar, about_me, services, achievements } =
        profile || {};

    const userSpecialistRole = getSpecialistRole(profile, entities);

    const [editServiceId, setEditServiceId] = useState<number | null>(null);

    const [newService, setNewService] = useState<{
        show: boolean;
        step: NewServiceStepTypes;
    }>({
        show: false,
        step: "category",
    });

    const [accountSetting, setAccountSettings] = useState<{ show: boolean }>({
        show: false,
    });

    const handleChangeAddService = (serviceId?: number | string) => {
        if (serviceId) {
            setEditServiceId(+serviceId);
            setNewService({ show: true, step: "description" });
            return;
        }
        setNewService({ show: true, step: "category" });
    };

    const handleClickPublishQuestionnaire = () => {
        publishSpecialistProfile()
            .unwrap()
            .catch(() =>
                setNotification({
                    type: "warn",
                    description:
                        entities
                            ?.specialist_details_profile_has_not_been_published
                            ?.value ?? "",
                })
            );
    };

    const readyPublished = !!(about_me && services?.length && services.every(service => {
        return service.is_remote || service.specialist_address || service.customer_address;
    }));

    useEffect(() => {
        updateProfile();
    }, []);

    useEffect(() => {
        const seenDraftPopUp = localStorage.getItem("seenDraftPopUp");

       if (profile?.is_draft && !seenDraftPopUp) {
            setOpenDraftModal(true);
       }
    }, [profile]);

    useEffect(() => {
        if (publishIsSuccess) {
            navigate(
                generatePath("/:currentLanguage/:role/lk/profile", {
                    currentLanguage,
                    role: "specialist",
                })
            );
        }
    }, [publishIsSuccess]);

    useEffect(() => {
        if (updateProfileIsLoading || publishIsLoading) {
            setLoading(true);
        } else {
            setLoading(false);
        }
    }, [updateProfileIsLoading, publishIsLoading]);

    const closeDraftModal = () => {
        localStorage.setItem("seenDraftPopUp", 'true');
        setOpenDraftModal(false);
    }

    if (newService.show) {
        return (
            <SpecialistNewService
                entities={entities}
                step={newService.step}
                changeStep={(step: NewServiceStepTypes, hide?: boolean) => {
                    if (hide) {
                        setNewService({ show: false, step: "category" });
                    } else {
                        setNewService((state) => ({ ...state, step }));
                    }
                }}
                serviceEditPayload={services?.find(
                    (service) => service?.id === editServiceId
                )}
            />
        );
    }

    if (accountSetting.show) {
        return (
            <Profile
                entities={entities}
                currentLanguage={currentLanguage}
                isCustomerOnUrl={false}
                isSpecialistOnUrl={true}
                profile={profile}
                historyBackHandler={() =>
                    setAccountSettings((prevState) => ({
                        ...prevState,
                        show: false,
                    }))
                }
            />
        );
    }

    return (
        <>
            <section className="specialist-profile">
                <div className="container">
                    <h1 className="specialist-profile__title title title--f46">
                        {entities?.profile_specialist_questionnaire?.value ||
                            ""}
                    </h1>
                    <ProfileHeader
                        name={name || ""}
                        familyName={family_name || ""}
                        visibleChangeAvatar={false}
                        photo={avatar?.original_url || ""}
                        setPhoto={() => null}
                        userSpecialistRole={userSpecialistRole}
                        entities={entities}
                    />
                    <div className="specialist-profile__content">
                        <AccountSettings
                            entities={entities}
                            linkHandler={() =>
                                setAccountSettings((prevState) => ({
                                    ...prevState,
                                    show: true,
                                }))
                            }
                            url={`${window.location.origin}/${currentLanguage}/${profile?.user_role}/lk/profile/account`}
                        />
                        <TellUsAboutYourself
                            currentLanguage={currentLanguage}
                            userAboutMe={about_me}
                            userVerification={"verified"}
                            entities={entities}
                        />
                        <ProfessionalAchievements
                            profile={profile}
                            entities={entities}
                            userVerification={"verified"}
                        />
                        <ServicesYouProvide
                            userService={services}
                            handleChangeAddService={handleChangeAddService}
                            entities={entities}
                        />
                        <div className="specialist-profile__controls">
                            <button
                                className="btn btn--transparent specialist-profile__block-btn  btn--bg-green"
                                type="button"
                                disabled={!readyPublished}
                                onClick={handleClickPublishQuestionnaire}
                            >
                                {
                                    !profile?.is_draft
                                        ? entities?.specialist_details_submit?.value || ""
                                        : entities?.profile_specialist_questionnaire_send_verification?.value || ""
                                }
                            </button>
                        </div>
                    </div>
                </div>

                <DraftSpecialistModal
                    entities={entities}
                    visible={openDraftModal}
                    changeVisible={closeDraftModal}
                />
            </section>
        </>
    );
};
